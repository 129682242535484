import React, { useState } from 'react';
import img from '../images/Web-developer.png';
import ScrollToTop from './ScrollToTop';

const Intro = () => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    const handleCallButtonClick = () => {
        const phoneNumber = '+49 15254123456'
        window.open(`tel:${phoneNumber}`, '_blank');
      };
    return (
        <>
            <ScrollToTop />
            <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about'>

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/3 flex flex-col lg:mx-4 justify-center">
                        <img alt="card img" className="rounded-t float-right" src={img} />
                    </div>
                    <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-2/3 px-8" data-aos="zoom-in" data-aos-delay="500">

                        <h3 className="text-3xl text-orange-master font-bold">Über uns</h3>
                        <div>
                            <p className='my-3 text-xl text-gray-600 font-semibold'>
                                {showMore ? (
                                    <>
                                        Sie möchten einen unkomplizierten und professionellen Umzugsservice aus einer Hand. Wir legen größten Wert auf Sorgfalt, Effizienz und Professionalität unserer Mitarbeiter.
                                    </>
                                ) : (
                                    <>
                                        Sie möchten einen unkomplizierten und professionellen Umzugsservice aus einer Hand. Wir legen größten Wert auf Sorgfalt, Effizienz und Professionalität unserer Mitarbeiter.
                                        <span className="text-orange-master cursor-pointer" onClick={toggleShowMore}>... Mehr anzeigen</span>
                                    </>
                                )}
                            </p>
                        </div>

                        {showMore && (
                            <>
                                <div>
                                    <p className='my-3 text-xl text-gray-600 font-semibold'>
                                        Für diesen vertrauenwürdigen Schritt ist es für uns besonders wichtig, auf die Bedürfnisse unserer Kunden einzugehen. Unsere Umzugshelfer verfügen über langjährige Erfahrung in der Umzugsbranche und unterstützen bei jeder Aufgabe und jedem Hindernis. Als kundenorientiertes Umzugsunternehmen bieten wir verschiedene Arten von Umzügen an.
                                    </p>
                                </div>
                                <div>
                                    <p className='my-3 text-xl text-gray-600 font-semibold'>
                                        Umzüge erfordern ein Höchstmaß an Planung und Organisation. Neben der Verpackung und dem Transport von Möbeln und Haushaltsgegenständen im privaten Sektor müssen auch ausreichend Umzugshelfer und ein geeigneter Transport zur Verfügung gestellt werden. Durch 1A Euroumzug können Sie sich Freiheit schaffen und uns das überlassen was wir am besten können.. und zwar umziehen!<span className="text-orange-master cursor-pointer" onClick={toggleShowMore}> Weniger anzeigen</span>
                                    </p>
                                </div>
                            </>
                        )}

                        <button 
                        onClick={handleCallButtonClick} 
                        className="text-white bg-gradient-to-r from-red-800 to-red-600 hover:from-red-700 hover:to-red-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group"
                        >
                            Kontaktiere uns
                            <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Intro;