import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import { useDocTitle } from './components/CustomHook';
import UmzugsservicePage from './pages/Umzugsservice/umzugsservicePage';
import SimplePage from './pages/Umzuge/UmzugeImAllgemeinen';
import PagesPage from './pages/Pagespages/Pagespage';
import JobsPage from './pages/Jobspages/Jobspage';
import Datenschutz from './pages/wichtigelinks/datenschutz';
import Impressum from './pages/wichtigelinks/impressum';
import AGB from './pages/wichtigelinks/agb';
import Kuchen from './pages/Umzugsservice/Kuchen';
import UmzugeAllgemeinen from './pages/Umzuge/UmzugeAllgemeinen';
import Privateumzuge from './pages/Umzuge/Privateumzuge';
import Behordenumzuge from './pages/Umzuge/Behordenumzuge';
import Buroumzuge from './pages/Umzuge/Buroumzuge';
import Firmenumzuge from './pages/Umzuge/Firmenumzuge';
import Seniorenumzuge from './pages/Umzuge/Seniorenumzuge';
import Auspackservice from './pages/Umzugsservice/Auspackservice';
import Klavier from './pages/Umzugsservice/Klavier';
import Umzugslift from './pages/Umzugsservice/Umzugslift';
import Mobel_einlagern from './pages/Umzugsservice/Mobel_einlagern';
import Halteverbot from './pages/Umzugsservice/Halteverbot';
import Burokraft from './pages/Jobspages/Burokraft';
import Monteur from './pages/Jobspages/Monteur';
import Fahrer from './pages/Jobspages/Fahrer';
import Umzugshelfer from './pages/Jobspages/Umzugshelfer';
import FAQ from './pages/Pagespages/FAQ';
import UnsereGalerie from './pages/Pagespages/UnsereGalerie';
import Transport from './pages/Umzugsservice/Transport';


function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("1A Euroumzug");

  return (
    <>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/angebot-anfragen" element={<DemoProduct />} />
          <Route path="/umzuge" element={<SimplePage />} />
          <Route path="/umzugsservice" element={<UmzugsservicePage />} />
          <Route path="/pages" element={<PagesPage />} />
          <Route path="/jobs" element={<JobsPage />} />
          <Route path="/datenschutz" element={<Datenschutz />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path='/AGB' element={<AGB />} />
          <Route path='/umzugsservice/Kuchen' element={<Kuchen />} />
          <Route path='/umzuge/Umzuge-im-allgemeinen' element={<UmzugeAllgemeinen />} />
          <Route path='/umzuge/Privateumzuge' element={<Privateumzuge />} />
          <Route path='/umzuge/Behordenumzuge' element={<Behordenumzuge />} />
          <Route path='/umzuge/Buroumzuge' element={<Buroumzuge />} />
          <Route path='/umzuge/Firmenumzuge' element={<Firmenumzuge />} />
          <Route path='/umzuge/Seniorenumzuge' element={<Seniorenumzuge />} />
          <Route path='/umzugsservice/Auspackservice' element={<Auspackservice />} />
          <Route path='/umzugsservice/Klavier' element={<Klavier />} />
          <Route path='/umzugsservice/Umzugslift' element={<Umzugslift />} />
          <Route path='/umzugsservice/Mobel_einlagern' element={<Mobel_einlagern />} />
          <Route path='/umzugsservice/Halteverbot' element={<Halteverbot />} />
          <Route path='/umzugsservice/Transport' element={<Transport />} />
          <Route path='/Job/Burokraft' element={<Burokraft />} />
          <Route path='/Job/Monteur' element={<Monteur />} />
          <Route path='/Job/Fahrer' element={<Fahrer />} />
          <Route path='/Job/Umzugshelfer' element={<Umzugshelfer />} />
          <Route path='/Pages/FAQ' element={<FAQ />} />
          <Route path='/Pages/UnsereGalerie' element={<UnsereGalerie />} />
        </Routes>
    </>
  );
}


export default App;
