import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';

import Unsere_Galerie1 from '../../images/Unsere Galerie/Unsere-Galerie1.jpg'
import Unsere_Galerie2 from '../../images/Unsere Galerie/Unsere-Galerie2.jpg'
import Unsere_Galerie3 from '../../images/Unsere Galerie/Unsere-Galerie3.jpg'
import Unsere_Galerie4 from '../../images/Unsere Galerie/Unsere-Galerie4.jpg'
import Unsere_Galerie5 from '../../images/Unsere Galerie/Unsere-Galerie5.jpg'
import Unsere_Galerie6 from '../../images/Unsere Galerie/Unsere-Galerie6.jpg'
import Unsere_Galerie7 from '../../images/Unsere Galerie/Unsere-Galerie7.jpg'
import Unsere_Galerie8 from '../../images/Unsere Galerie/Unsere-Galerie8.jpg'
import Unsere_Galerie9 from '../../images/Unsere Galerie/Unsere-Galerie9.jpg'
import Unsere_Galerie10 from '../../images/Unsere Galerie/Unsere-Galerie10.jpg'
import Unsere_Galerie11 from '../../images/Unsere Galerie/Unsere-Galerie11.jpg'
import Unsere_Galerie12 from '../../images/Unsere Galerie/Unsere-Galerie12.jpg'
import Unsere_Galerie13 from '../../images/Unsere Galerie/Unsere-Galerie13.jpg'
import Unsere_Galerie14 from '../../images/Unsere Galerie/Unsere-Galerie14.jpg'
import Unsere_Galerie15 from '../../images/Unsere Galerie/Unsere-Galerie15.jpg'
import Unsere_Galerie16 from '../../images/Unsere Galerie/Unsere-Galerie16.jpg'
import Unsere_Galerie17 from '../../images/Unsere Galerie/Unsere-Galerie17.jpg'
import Unsere_Galerie18 from '../../images/Unsere Galerie/Unsere-Galerie18.jpg'

const UnsereGalerie = () => {

    const galleryItems = [
        { id: 1, src: Unsere_Galerie1, title: ' Unsere Galerie 1' },
        { id: 2, src: Unsere_Galerie2, title: ' Unsere Galerie 2' },
        { id: 3, src: Unsere_Galerie3, title: ' Unsere Galerie 3' },
        { id: 4, src: Unsere_Galerie4, title: ' Unsere Galerie 4' },
        { id: 5, src: Unsere_Galerie5, title: ' Unsere Galerie 5' },
        { id: 6, src: Unsere_Galerie6, title: ' Unsere Galerie 6' },
        { id: 7, src: Unsere_Galerie7, title: ' Unsere Galerie 7' },
        { id: 8, src: Unsere_Galerie8, title: ' Unsere Galerie 8' },
        { id: 9, src: Unsere_Galerie9, title: ' Unsere Galerie 9' },
        { id: 10, src: Unsere_Galerie10, title: ' Unsere Galerie 10' },
        { id: 11, src: Unsere_Galerie11, title: ' Unsere Galerie 11' },
        { id: 12, src: Unsere_Galerie12, title: ' Unsere Galerie 12' },
        { id: 13, src: Unsere_Galerie13, title: ' Unsere Galerie 13' },
        { id: 14, src: Unsere_Galerie14, title: ' Unsere Galerie 14' },
        { id: 15, src: Unsere_Galerie15, title: ' Unsere Galerie 15' },
        { id: 16, src: Unsere_Galerie16, title: ' Unsere Galerie 16' },
        { id: 17, src: Unsere_Galerie17, title: ' Unsere Galerie 17' },
        { id: 18, src: Unsere_Galerie18, title: ' Unsere Galerie 18' },
        // Add more items as needed
    ];


    return (
        <section>
            <ScrollToTop />
            <NavBar />


            <div className="container mx-auto my-8 mt-40">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {galleryItems.map((item) => (
                        <div key={item.id} className="mb-2 p-5">
                            <img
                                src={item.src}
                                alt={item.title}
                                className="w-full h-64 object-cover rounded-lg transition-transform transform scale-100 group-hover:scale-110"
                            />
                        </div>
                    ))}
                </div>
            </div>

            <Footer />
        </section>
    );
};

export default UnsereGalerie;
