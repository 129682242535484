// CustomAlert.js
import React from 'react';
import { Link } from 'react-router-dom';

const CustomAlert = ({ message }) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg p-8">
                <p className="text-xl font-bold mb-4">{message}</p>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                    <Link to='/'> OK </Link>

                </button>
            </div>
        </div>
    );
};

export default CustomAlert;
