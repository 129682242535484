import React  from 'react';
import { useDocTitle } from '../../components/CustomHook';
import Footer from '../../components/Footer';
import NavBar from '../../components/Navbar/NavBar';

const Datenschutz = () => {
    useDocTitle("1A Euroumzug");    
    return (
        <>
            <div>
                <NavBar />
            </div>

            <div className='container px-5 py-24 mx-auto'>
                <p className='mb-10 text-4xl text-red-600'>Datenschutzerklärung</p>
                <div>
                    <p className='mt-5 text-xl'>Datenschutz</p>
                    <p className='mb-5 text-left'>

                        Wir haben diese Datenschutzerklärung (Fassung 16.03.2020-311162664) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
                        Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
                    </p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Cookies</p>
                    <p className='mb-5 text-left'>
                        Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern. Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
                    </p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Was genau sind Cookies?</p>
                    <p className='mb-5 text-left'>
                        Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.

                    </p>
                    <p className='mb-5 text-left'>
                        Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.

                    </p>
                    <p className='mb-5 text-left'>
                        Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.

                    </p>
                    <p className='mb-5 text-left'>
                        Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.

                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>So können zum Beispiel Cookie-Daten aussehen:</p>
                    <div>Name: _ga</div>
                    <div>Ablaufzeit: 2 Jahre</div>
                    <div>Verwendung: Unterscheidung der Webseitenbesucher</div>
                    <div>Beispielhafter Wert: GA1.2.1326744211.152311162664</div>
                    <div>Ein Browser sollte folgende Mindestgrößen unterstützen:</div>
                    <div>Ein Cookie soll mindestens 4096 Bytes enthalten können</div>
                    <div>Pro Domain sollen mindestens 50 Cookies gespeichert werden können</div>
                    <div>Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</div>
                    <div>Welche Arten von Cookies gibt es?</div>
                    <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>So können zum Beispiel Cookie-Daten aussehen:</p>
                    <div>Man kann 4 Arten von Cookies unterscheiden:</div>
                    <div>Unbedingt notwendige Cookies</div>

                    <p>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Funktionelle Cookies</p>
                    <p>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Zielorientierte Cookies</p>
                    <p>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Werbe-Cookies</p>
                    <p>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Wie kann ich Cookies löschen?</p>
                    <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <div>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</div>
                    <div>Safari: Verwalten von Cookies und Websitedaten mit Safari</div>
                    <div>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</div>
                    <div>Internet Explorer: Löschen und Verwalten von Cookies</div>
                    <div>Microsoft Edge: Löschen und Verwalten von Cookies</div>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers oder tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Wie sieht es mit meinem Datenschutz aus?</p>
                    <p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht zurückscheuen, empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Speicherung persönlicher Daten</p>
                    <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
                        Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
                        Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Rechte laut Datenschutzgrundverordnung</p>
                    <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Recht auf Berichtigung (Artikel 16 DSGVO)</p>
                    <p>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</p>
                    <p>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</p>
                    <p>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)</p>
                    <p>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</p>
                    <p>Widerspruchsrecht (Artikel 21 DSGVO)</p>
                    <p>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO) Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI) wenden.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Auswertung des Besucherverhaltens</p>
                    <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>TLS-Verschlüsselung mit https</p>
                    <p></p>
                    <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>

                </div>
                <div>
                    <p className='mt-5 text-xl'>Google Maps Datenschutzerklärung</p>
                    <p>Name: NID</p>
                    <p>Ablaufzeit: nach 6 Monaten</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Was ist Google Maps?</p>

                    <p>Google Maps ist ein Online-Kartendienst der Firma Google Inc. Mit Google Maps können Sie im Internet über einen PC oder über eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts per HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild an. Dank der Street View Bilder und den qualitativ hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</p>

                </div>
                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir Google Maps auf unserer Website?</p>

                    <p>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Website zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Dank Google Maps sehen Sie auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden von Google Maps gespeichert?</p>

                    <p>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Website eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.</p>

                </div>
                <div>
                    <p className='mt-5 text-xl'>Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:</p>

                    <p>Name: NID</p>
                    <p>Ablaufzeit: nach 6 Monaten</p>
                    <p>Verwendung: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer</p>
                    <p>maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, persönliche Einstellungen des Users für Werbezwecke zu sammeln.</p>
                    <p>Beispielwert: 188=h26c1Ktha7fCQTx8rXgLyATyITJ311162664</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Anmerkung: Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen bei Google nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>

                    <p>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: https://www.google.com/about/datacenters/inside/locations/?hl=de
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server beeinträchtigt, bleiben die Daten mit hoher Wahrscheinlich dennoch geschützt.</p>
                    <p>Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem sie einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löschen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>
                    <p>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- bzw. ausschalten.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</p>
                    <p>Safari: Verwalten von Cookies und Websitedaten mit Safari</p>
                    <p>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</p>
                    <p>Internet Explorer: Löschen und Verwalten von Cookies</p>
                    <p>Microsoft Edge: Löschen und Verwalten von Cookies</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI. Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter https://policies.google.com/privacy?hl=de.</p>

                </div>
                <div>
                    <p className='mt-5 text-xl'>Google Fonts Datenschutzerklärung</p>
                    <p>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite.
                        Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Was sind Google Fonts?</p>
                    <p>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die die Google LLC zur freien Verwendung bereitstellt.
                    </p>
                    <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen. Somit können wir sie frei verwenden, ohne dafür Lizenzgebühren zu zahlen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir Google Fonts auf unserer Webseite?</p>
                    <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sogenannte sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser ( Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod).</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können. Nach dem Art. 6 Abs. 1 f lit. F DSGVO stellt das bereits ein „berechtigtes Interesse“ an der Verarbeitung von personenbezogenen Daten dar. Unter „berechtigtem Interesse“ versteht man in diesem Fall sowohl rechtliche als auch wirtschaftliche oder ideelle Interessen, die vom Rechtssystem anerkannt werden.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden von Google gespeichert?</p>
                    <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um die Erfassung, Speicherung und Verwendung von Endnutzerdaten auf das zu reduzieren, was für eine effiziente Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.</p>
                    <p>Google Fonts speichert CSS- und Font-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google die Beliebtheit der Schriften feststellen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. BigQuery ist ein Webservice von Google für Unternehmen, die große Datenmengen bewegen und analysieren wollen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie IP-Adresse, Spracheinstellungen, Bildschirmauflösung des Browsers, Version des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>
                    <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf Ihren Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.</p>
                    <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>
                    <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=311162664 kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
                    <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=311162664. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig (beinahe unmöglich), von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Google Fonts Lokal Datenschutzerklärung</p>
                    <p>Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Server von Google und somit auch keine Datenübertragung bzw. Speicherung.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Was sind Google Fonts?</p>
                    <p>Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die die Google LLC zur freien Verwendung bereitstellt. Mit Google Fonts könnte man die Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zum Google-Server zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.
                        Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=311162664.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Automatische Datenspeicherung</p>
                    <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.
                        Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>die Adresse (URL) der aufgerufenen Webseit</p>
                    <p>Browser und Browserversion</p>
                    <p>das verwendete Betriebssystem</p>
                    <p>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</p>
                    <p>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</p>
                    <p>Datum und Uhrzeit</p>
                    <p>in Dateien (Webserver-Logfiles).</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Facebook Datenschutzerklärung</p>
                    <p>Wir verwenden auf unserer Webseite ausgewählte Facebook Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, das bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen können.</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Was sind Facebook-Tools?</p>
                    <p>Neben vielen anderen Produkten bietet Facebook auch die sogenannten “Facebook Business Tools” an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:.</p>

                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Facebook-Pixel</p>
                    <p>Aoziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)</p>
                    <p>Facebook Login</p>
                    <p>ccount Kit</p>
                    <p>APIs (Programmierschnittstelle)</p>
                    <p>DKs (Sammlung von Programmierwerkzeugen)</p>
                    <p>Plattform-Integrationen</p>
                    <p>Plugins</p>
                    <p>Codes</p>
                    <p>Spezifikationen</p>
                    <p>Dokumentationen</p>
                    <p>Technologien und Dienstleistungen</p>
                    <p>Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu erhalten.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir Facebook-Tools auf unserer Webseite?</p>
                    <p>Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden von Facebook-Tools gespeichert?</p>
                    <p>Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.
                        Facebook verwendet diese Informationen, um die Daten mit den ihren eigenen Daten, die es von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der Verschlüsselung von Daten.
                        Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem bereits erwähnten Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecken. Viele dieser Daten werden über Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abhängig, ob Sie Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine Informationen über die Verwendung von Facebook-Cookies erfahren Sie auch auf https://www.facebook.com/policies/cookies.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>
                    <p>Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo Ihre Daten gespeichert werden. Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48 Stunden gelöscht.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern? Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.</p>
                    <p>Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto vollständig löschen. Und so funktioniert das Löschen Ihres Facebook-Kontos:</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <ul>
                        <li> Klicken Sie rechts bei Facebook auf Einstellungen.</li>
                        <li>Anschließend klicken Sie in der linken Spalte auf „Deine Facebook-Informationen“.</li>
                        <li>Nun klicken Sie “Deaktivierung und Löschung”.</li>
                        <li>Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und Konto löschen“</li>
                        <li>Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf „Konto löschen“</li>
                    </ul>
                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</p>
                    <p>Safari: Verwalten von Cookies und Websitedaten mit Safari</p>
                    <p>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</p>
                    <p>Internet Explorer: Löschen und Verwalten von Cookies</p>
                    <p>Microsoft Edge: Löschen und Verwalten von Cookies</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
                    <p>Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC. Wir hoffen wir haben Ihnen die wichtigsten Informationen über die Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten verwendet, empfehlen wir Ihnen die Datenrichtlinien auf</p>
                    <p>https://www.facebook.com/about/privacy/update.</p>

                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Facebook Soziale Plug-ins Datenschutzerklärung</p>
                    <p>Auf unserer Webseite sind sogenannte soziale Plug-ins des Unternehmens Facebook Inc. eingebaut. Sie erkennen diese Buttons am klassischen Facebook-Logo, wie dem „Gefällt mir“-Button (die Hand mit erhobenem Daumen) oder an einer eindeutigen „Facebook Plug-in“-Kennzeichnung. Ein soziales Plug-in ist ein kleiner Teil von Facebook, der in unsere Seite integriert ist. Jedes Plug-in hat eine eigene Funktion. Die am meisten verwendeten Funktionen sind die bekannten “Gefällt mir”- und “Teilen”-Buttons.
                        Folgende soziale Plug-ins werden von Facebook angeboten:</p>

                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>“Speichern”-Button
                        “Gefällt mir”-Button, Teilen, Senden und Zitat
                        Seiten-Plug-in
                        Kommentare
                        Messenger-Plug-in
                        Eingebettete Beiträge und Videoplayer
                        Gruppen-Plug-in</p>
                    <p>Auf https://developers.facebook.com/docs/plugins erhalten Sie nähere Informationen, wie die einzelnen Plug-ins verwendet werden. Wir nützen die sozialen Plug-ins einerseits, um Ihnen ein besseres Usererlebnis auf unserer Seite zu bieten, andererseits weil Facebook dadurch unsere Werbeanzeigen optimieren kann.</p>

                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Sofern Sie ein Facebook-Konto haben oder facebook.com schon mal besucht haben, hat Facebook bereits mindestens ein Cookie in Ihrem Browser gesetzt. In diesem Fall sendet Ihr Browser über dieses Cookie Informationen an Facebook, sobald Sie unsere Seite besuchen bzw. mit sozialen Plug-ins (z.B. dem „Gefällt mir“-Button) interagieren.</p>
                    <p>Die erhaltenen Informationen werden innerhalb von 90 Tagen wieder gelöscht bzw. anonymisiert. Laut Facebook gehören zu diesen Daten Ihre IP-Adresse, welche Webseite Sie besucht haben, das Datum, die Uhrzeit und weitere Informationen, die Ihren Browser betreffen.
                        Um zu verhindern, dass Facebook während Ihres Besuches auf unserer Webseite viele Daten sammelt und mit den Facebook-Daten verbindet, müssen Sie sich während des Webseitenbesuchs von Facebook abmelden (ausloggen).</p>

                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Falls Sie bei Facebook nicht angemeldet sind oder kein Facebook-Konto besitzen, sendet Ihr Browser weniger Informationen an Facebook, weil Sie weniger Facebook-Cookies haben. Dennoch können Daten wie beispielsweise Ihre IP-Adresse oder welche Webseite Sie besuchen an Facebook übertragen werden. Wir möchten noch ausdrücklich darauf hinweisen, dass wir über die genauen Inhalte der Daten nicht exakt Bescheid wissen. Wir versuchen aber Sie nach unserem aktuellen Kenntnisstand so gut als möglich über die Datenverarbeitung aufzuklären. Wie Facebook die Daten nutzt, können Sie auch in den Datenrichtline des Unternehmens unter</p>
                    <p>https://www.facebook.com/about/privacy/update nachlesen.</p>
                    <p>Folgende Cookies werden in Ihrem Browser mindestens gesetzt, wenn Sie eine Webseite mit sozialen Plug-ins von Facebook besuchen:
                        Name: dpr.</p>
                    <p>Wert: keine Angabe</p>
                    <p>Verwendungszweck: Dieses Cookie wird verwendet, damit die sozialen Plug-ins auf unserer Webseite funktionieren.
                        Ablaufdatum: nach Sitzungsende
                    </p>
                    <p>Name: fr
                        Wert: 0jieyh4311162664c2GnlufEJ9..Bde09j…1.0.Bde09j
                        <p>Verwendungszweck: Auch das Cookie ist nötig, dass die Plug-ins einwandfrei funktionieren.</p>
                        <p> Ablaufdatum: nach 3 Monaten</p></p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Anmerkung: Diese Cookies wurden nach einem Test gesetzt, auch wenn Sie nicht Facebook-Mitglied sind.</p>

                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf http://www.youronlinechoices.com/de/praferenzmanagement/grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.</p>
                    <p>Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf https://www.facebook.com/policy.php.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Facebook Login Datenschutzerklärung Wir haben auf unserer Seite das praktische Facebook Login integriert. So können Sie sich bei uns ganz einfach mit Ihrem Facebook-Konto einloggen, ohne ein weiteres Benutzerkonto anlegen zu müssen. Wenn Sie sich entscheiden, Ihre Registrierung über das Facebook Login zu machen, werden Sie auf das Social Media Network Facebook weitergeleitet. Dort erfolgt die Anmeldung über Ihre Facebook Nutzerdaten. Durch dieses Login-Verfahren werden Daten über Sie bzw. Ihr Userverhalten gespeichert und an Facebook übermittelt.</p>
                    <p>Um die Daten zu speichern, benutzt Facebook verschiedene Cookies. Im Folgenden zeigen wir Ihnen die wichtigsten Cookies, die in Ihrem Browser gesetzt werden bzw. schon bestehen, wenn Sie sich über das Facebook Login auf unserer Seite anmelden:</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Name: fr
                        Wert: 0jieyh4c2GnlufEJ9..Bde09j…1.0.Bde09j
                        Verwendungszweck: Dieses Cookie wird verwendet, damit das sozialen Plugin auf unserer Webseite bestmöglich funktioniert. Ablaufdatum: nach 3 Monaten
                        Name: datr
                        Wert: 4Jh7XUA2311162664SEmPsSfzCOO4JFFl
                        Verwendungszweck: Facebook setzt das “datr”-Cookie, wenn ein Webbrowser auf facebook.com zugreift, und das Cookie hilft, Anmeldeaktivitäten zu identifizieren und die Benutzer zu schützen. Ablaufdatum: nach 2 Jahren
                        Name: _js_datr
                        Wert: deleted
                        Verwendungszweck: Dieses Session-Cookie setzt Facebook zu Trackingzwecken, auch wenn Sie kein Facebook-Konto haben oder ausgeloggt sind. Ablaufdatum: nach Sitzungsende</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Anmerkung: Die angeführten Cookies sind nur eine kleine Auswahl der Cookies, die Facebook zur Verfügung stehen. Weitere Cookies sind beispielsweise _ fbp, sb oder wd. Eine vollständige Aufzählung ist nicht möglich, da Facebook über eine Vielzahl an Cookies verfügt und diese variabel einsetzt.</p></div>
                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Der Facebook Login bietet Ihnen einerseits einen schnellen und einfachen Registrierungsprozess, andererseits haben wir so die Möglichkeit Daten mit Facebook zu teilen. Dadurch können wir unser Angebot und unsere Werbeaktionen besser an Ihre Interessen und Bedürfnisse anpassen. Daten, die wir auf diese Weise von Facebook erhalten, sind öffentliche Daten wie</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Ihr Facebook-Name
                        Ihr Profilbild
                        eine hinterlegte E-Mail-Adresse
                        Freundeslisten
                        Buttons-Angaben (z.B. „Gefällt mir“-Button)
                        Geburtstagsdatum
                        Sprache
                        Wohnort</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Im Gegenzug stellen wir Facebook Informationen über Ihre Aktivitäten auf unserer Webseite bereit. Das sind unter anderem Informationen über Ihr verwendetes Endgerät, welche Unterseiten Sie bei uns besuchen oder welche Produkte Sie bei uns erworben haben.
                        Durch die Verwendung von Facebook Login willigen Sie der Datenverarbeitung ein. Sie können diese Vereinbarung jederzeit widerrufen. Wenn Sie mehr Informationen über die Datenverarbeitung durch Facebook erfahren wollen, empfehlen wir Ihnen die Facebook-Datenschutzerklärung unter https://de-de.facebook.com/policy.php.</p>

                    <p>Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern.</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>Instagram Datenschutzerklärung</p>
                    <p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Facebook Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.
                        Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Facebook Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Facebook-Datenrichtlinien selbst.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Was ist Instagram?</p>
                    <p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir Instagram auf unserer Webseite?</p>
                    <p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.
                        Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.
                    </p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden von Instagram gespeichert?</p>
                    <p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Wichtig zu erwähnen ist, dass diese Kundendaten erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat abgeglichen.
                        Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.
                        Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder www.instagram.com besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.
                        Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Diese Cookies wurden bei unserem Test verwendet:</p>
                    <p>Name: csrftoken
                        Wert: “”
                        Verwendungszweck: Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.
                        Ablaufdatum: nach einem Jahr
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Name: mid
                        Wert: “”
                        Verwendungszweck: Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.
                        Ablaufdatum: nach Ende der Sitzung

                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Name: fbsr_311162664124024
                        Wert: keine Angaben
                        Verwendungszweck: Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App. Ablaufdatum: nach Ende der Sitzung

                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Name: rur
                        Wert: ATN
                        Verwendungszweck: Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.
                        Ablaufdatum: nach Ende der Sitzung
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Name: urlgen
                        Wert: “\”194.96.75.33\”: 1901:1iEtYv:Y833k2_UjKvXgYe311162664”
                        Verwendungszweck: Dieses Cookie dient den Marketingzwecken von Instagram. Ablaufdatum: nach Ende der Sitzung
                        Anmerkung: Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>
                    <p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>
                    <p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>Und so funktioniert die Löschung des Instagram-Kontos:</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.
                        Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.
                        Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Hier zeigen wir Ihnen die Anleitungen der wichtigsten Browser.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten</p>
                    <p>Safari: Verwalten von Cookies und Websitedaten mit Safari</p>
                    <p>Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</p>
                    <p>Internet Explorer: Löschen und Verwalten von Cookies</p>
                    <p>Microsoft Edge: Löschen und Verwalten von Cookies</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.
                    </p>

                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Instagram ist ein Tochterunternehmen von Facebook Inc. und Facebook ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework. Dieses Framework stellt eine korrekte Datenübertragung zwischen den USA und der Europäischen Union sicher. Unter https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC erfahren Sie mehr darüber. Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf https://help.instagram.com/519522125107875 können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.
                    </p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>YouTube Datenschutzerklärung</p>
                    <p>Wir haben auf unserer Webseite YouTube-Videos eingebaut. So können wir Ihnen interessante Videos direkt auf unserer Seite präsentieren. YouTube ist ein Videoportal, das seit 2006 eine Tochterfirma von Google LLC ist. Betrieben wird das Videoportal durch YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie auf unserer Webseite eine Seite aufrufen, die ein YouTube-Video eingebettet hat, verbindet sich Ihr Browser automatisch mit den Servern von YouTube bzw. Google. Dabei werden (je nach Einstellungen) verschiedene Daten übertragen. Für die gesamte Datenverarbeitung ist Google verantwortlich und es gilt somit auch der Datenschutz von Google.
                        Im Folgenden wollen wir Ihnen genauer erklären, welche Daten verarbeitet werden, warum wir YouTube-Videos eingebunden haben und wie Sie Ihre Daten verwalten oder löschen können.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Was ist YouTube?</p>
                    <p>Auf YouTube können die User kostenlos Videos ansehen, bewerten, kommentieren und selbst hochladen. Über die letzten Jahre wurde YouTube zu einem der wichtigsten Social-Media-Kanäle weltweit. Damit wir Videos auf unserer Webseite anzeigen können, stellt YouTube einen Codeausschnitt zur Verfügung, den wir auf unserer Seite eingebaut haben.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>
                    <p>Warum verwenden wir YouTube-Videos auf unserer Webseite? YouTube ist die Videoplattform mit den meisten Besuchern und dem besten Content. Wir sind bemüht, Ihnen die bestmögliche User-Erfahrung auf unserer Webseite zu bieten. Und natürlich dürfen interessante Videos dabei nicht fehlen. Mithilfe unserer eingebetteten Videos stellen wir Ihnen neben unseren Texten und Bildern weiteren hilfreichen Content zur Verfügung. Zudem wird unsere Webseite auf der Google-Suchmaschine durch die eingebetteten Videos leichter gefunden. Auch wenn wir über Google Ads Werbeanzeigen schalten, kann Google – dank der gesammelten Daten – diese Anzeigen wirklich nur Menschen zeigen, die sich für unsere Angebote interessieren.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden von YouTube gespeichert?</p>

                    <p>In der folgenden Liste zeigen wir Cookies, die in einem Test im Browser gesetzt wurden. Wir zeigen einerseits Cookies, die ohne angemeldeten YouTube-Konto gesetzt werden. Andererseits zeigen wir Cookies, die mit angemeldetem Account gesetzt werden. Die Liste kann keinen Vollständigkeitsanspruch erheben, weil die Userdaten immer von den Interaktionen auf YouTube abhängen.

                    </p>
                </div>


                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Name: YSC
                        Wert: b9-CV6ojI5Y
                        Verwendungszweck: Dieses Cookie registriert eine eindeutige ID, um Statistiken des gesehenen Videos zu speichern.
                        Ablaufdatum: nach Sitzungsende

                    </p>
                </div>



                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Name: PREF
                        Wert: f1=50000000
                        Verwendungszweck: Dieses Cookie registriert ebenfalls Ihre eindeutige ID. Google bekommt über PREF Statistiken, wie Sie YouTube-Videos auf unserer Webseite verwenden.
                        Ablaufdatum: nach 8 Monate
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Name: GPS
                        Wert: 1
                        Verwendungszweck: Dieses Cookie registriert Ihre eindeutige ID auf mobilen Geräten, um den GPS-Standort zu tracken.
                        Ablaufdatum: nach 30 Minuten


                    </p>

                    <p>Name: VISITOR_INFO1_LIVE
                        Wert: 95Chz8bagyU
                        Verwendungszweck: Dieses Cookie versucht die Bandbreite des Users auf unseren Webseiten (mit eingebautem YouTube-Video) zu schätzen.
                        Ablaufdatum: nach 8 Monaten
                        Weitere Cookies, die gesetzt werden, wenn Sie mit Ihrem YouTube-Konto angemeldet sind:


                    </p>

                    <p>Name: APISID
                        Wert: zILlvClZSkqGsSwI/AU1aZI6HY7311162664-
                        Verwendungszweck: Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Genützt werden die Daten für personalisierte Werbeanzeigen.
                        Ablaufdatum: nach 2 Jahre


                    </p>


                    <p>Name: CONSENT
                        Wert: YES+AT.de+20150628-20-0
                        Verwendungszweck: Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.
                        Ablaufdatum: nach 19 Jahren

                    </p>

                    <p>Name: HSID
                        Wert: AcRwpgUik9Dveht0I
                        Verwendungszweck: Dieses Cookie wird verwendet, um ein Profil über Ihre Interessen zu erstellen. Diese Daten helfen personalisierte Werbung anzeigen zu können.
                        Ablaufdatum: nach 2 Jahren

                    </p>

                    <p>Name: LOGIN_INFO
                        Wert: AFmmF2swRQIhALl6aL…
                        Verwendungszweck: In diesem Cookie werden Informationen über Ihre Login-Daten gespeichert.
                        Ablaufdatum: nach 2 Jahren

                    </p>
                    <p>Name: SAPISID
                        Wert: 7oaPxoG-pZsJuuF5/AnUdDUIsJ9iJz2vdM
                        Verwendungszweck: Dieses Cookie funktioniert, indem es Ihren Browser und Ihr Gerät eindeutig identifiziert. Es wird verwendet, um ein Profil über Ihre Interessen zu erstellen.
                        Ablaufdatum: nach 2 Jahren

                    </p>
                    <p>Name: SID
                        Wert: oQfNKjAsI311162664-
                        Verwendungszweck: Dieses Cookie speichert Ihre Google-Konto-ID und Ihren letzten Anmeldezeitpunkt in digital signierter und verschlüsselter Form.
                        Ablaufdatum: nach 2 Jahren

                    </p>
                    <p>Name: SIDCC
                        Wert: AN0-TYuqub2JOcDTyL
                        Verwendungszweck: Dieses Cookie speichert Informationen, wie Sie die Webseite nutzen und welche Werbung Sie vor dem Besuch auf unserer Seite möglicherweise gesehen haben.
                        Ablaufdatum: nach 3 Monaten

                    </p>

                </div>


                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>

                    <p>Die Daten, die YouTube von Ihnen erhält und verarbeitet werden auf den Google-Servern gespeichert. Die meisten dieser Server befinden sich in Amerika. Unter https://www.google.com/about/datacenters/inside/locations/?hl=de sehen Sie genau wo sich die Google-Rechenzentren befinden. Ihre Daten sind auf den Servern verteilt. So sind die Daten schneller abrufbar und vor Manipulation besser geschützt.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Die erhobenen Daten speichert Google unterschiedlich lang. Manche Daten können Sie jederzeit löschen, andere werden automatisch nach einer begrenzten Zeit gelöscht und wieder andere werden von Google über längere Zeit gespeichert. Einige Daten (wie Elemente aus „Meine Aktivität“, Fotos oder Dokumente, Produkte), die in Ihrem Google-Konto gespeichert sind, bleiben so lange gespeichert, bis Sie sie löschen. Auch wenn Sie nicht in einem Google-Konto angemeldet sind, können Sie einige Daten, die mit Ihrem Gerät, Browser oder App verknüpft sind, löschen.
                    </p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>

                    <p>Grundsätzlich können Sie Daten im Google Konto manuell löschen. Mit der 2019 eingeführten automatische Löschfunktion von Standort- und Aktivitätsdaten werden Informationen abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht.
                        Unabhängig, ob Sie ein Google-Konto haben oder nicht, können Sie Ihren Browser so konfigurieren, dass Cookies von Google gelöscht bzw. deaktiviert werden. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:
                    </p>

                    <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                        Safari: Verwalten von Cookies und Websitedaten mit Safari
                        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
                        Internet Explorer: Löschen und Verwalten von Cookies
                        Microsoft Edge: Löschen und Verwalten von Cookies</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht. Da YouTube ein Tochterunternehmen von Google ist, gibt es eine gemeinsame Datenschutzerklärung. Wenn Sie mehr über den Umgang mit Ihren Daten erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung unter https://policies.google.com/privacy?hl=de.</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>Google reCAPTCHA Datenschutzerklärung</p>

                    <p>Unser oberstes Ziel ist es, dass unsere Webseite für Sie und für uns bestmöglich geschützt und sicher ist. Um das zu gewährleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Mit reCAPTCHA können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg, unerwünschte Information, die uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie das genau funktioniert und vor allem welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.
                        Rechtsgrundlage für die Verwendung ist Artikel 6 (1) f (Rechtmäßigkeit der Verarbeitung), denn es besteht ein berechtigtes Interesse diese Webseite vor Bots und Spam-Software zu schützen.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Was ist reCAPTCHA?</p>

                    <p>eCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch nicht-menschliche Besucher schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet ausfüllen. Ein Captcha-Dienst ist ein automatischer Turing-Test, der sicherstellen soll, dass eine Handlung im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei Captchas übernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben, die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext eingebunden und dann läuft das Tool im Hintergrund und analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet die Software einen sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein Mensch sind. ReCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen könnten.
                        Rechtsgrundlage für die Verwendung ist Artikel 6 (1) f (Rechtmäßigkeit der Verarbeitung), denn es besteht ein berechtigtes Interesse diese Webseite vor Bots und Spam-Software zu schützen.</p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir reCAPTCHA auf unserer Website?</p>

                    <p>Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma Google. So können wir uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden Daten an Google übermittelt, die Google verwendet, um festzustellen, ob Sie auch wirklich ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA können wir solche Botangriffe vermeiden.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden von reCAPTCHA gespeichert?</p>

                    <p>ReCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch wirklich von Menschen stammen. Es kann also die IP-Adresse und andere Daten, die Google für den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind. Zuerst prüft der reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser und erfasst einen Schnappschuss Ihres Browserfensters.
                        Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Referrer URL (die Adresse der Seite von der der Besucher kommt)</p>

                    <p>IP-Adresse (z.B. 256.123.123.1)
                        Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)
                        Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
                        Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur ausführen wird gespeichert)
                        Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC voreingestellt haben wird gespeichert)</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen sammeln) Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht) Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten Google genau speichert, erfährt man von Google nicht im Detail.
                    </p>
                    <p>Folgende Cookies werden von reCAPTCHA verwendet: Hierbei beziehen wir uns auf die reCAPTCHA Demo-Version von Google unter https://www.google.com/recaptcha/api2/demo. All diese Cookies benötigen zu Trackingzwecken eine eindeutige Kennung. Hier ist eine Liste an Cookies, die Google reCAPTCHA auf der Demo-Version gesetzt hat:

                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Name: IDE</p>

                    <p>Ablaufzeit: nach einem Jahr
                        Verwendung: Dieses Cookie wird von der Firma DoubleClick (gehört auch Google) gesetzt, um die Aktionen eines Users auf der Webseite im Umgang mit Werbeanzeigen zu registrieren und zu melden. So kann die Werbewirksamkeit gemessen und entsprechende Optimierungsmaßnahmen getroffen werden. IDE wird in Browsern unter der Domain doubleclick.net gespeichert.
                        Beispielwert: WqTUmlnmv_qXyi_DGNPLESKnRNrpgXoy1K-pAZtAkMbHI-311162664
                    </p>
                    <p>Name: 1P_JAR
                        Ablaufzeit: nach einem Monat
                        Verwendung: Dieses Cookie sammelt Statistiken zur Website-Nutzung und misst Conversions. Eine Conversion entsteht z.B., wenn ein User zu einem Käufer wird. Das Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden. Weiters kann man mit dem Cookie vermeiden, dass ein User dieselbe Anzeige mehr als einmal zu Gesicht bekommt.
                        Beispielwert: 2019-5-14-12</p>

                    <p>Name: ANID
                        Ablaufzeit: nach 9 Monaten
                        Verwendung: Viele Infos konnten wir über dieses Cookie nicht in Erfahrung bringen. In der Datenschutzerklärung von Google wird das Cookie im Zusammenhang mit „Werbecookies“ wie z. B. “DSID”, “FLC”, “AID”, “TAID” erwähnt. ANID wird unter Domain google.com gespeichert.
                        Beispielwert: U7j1v3dZa3111626640xgZFmiqWppRWKOr</p>

                    <p>Name: CONSENT
                        Ablaufzeit: nach 19 Jahren
                        Verwendung: Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen, Betrügereien von Anmeldeinformationen zu verhindern und Userdaten vor unbefugten Angriffen zu schützen.
                        Beispielwert: YES+AT.de+20150628-20-0</p>

                    <p>Name: NID
                        Ablaufzeit: nach 6 Monaten
                        Verwendung: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre meist eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt um persönliche Einstellungen des Users für Werbezwecke zu sammeln.
                        Beispielwert: 0WmuWqy311162664zILzqV_nmt3sDXwPeM5Q</p>

                    <p>Name: DV
                        Ablaufzeit: nach 10 Minuten
                        Verwendung: Sobald Sie das „Ich bin kein Roboter“-Häkchen angekreuzt haben, wird dieses Cookie gesetzt. Das Cookie wird von Google Analytics für personalisierte Werbung verwendet. DV sammelt Informationen in anonymisierter Form und wird weiters benutzt, um User-Unterscheidungen treffen zu können.
                        Beispielwert: gEAABBCjJMXcI0dSAAAANbqc311162664</p>
                    <p>Anmerkung: Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google erfahrungsgemäß die Wahl ihrer Cookies immer wieder auch verändert.</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>

                    <p>Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen. Wo genau diese Daten gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Bestätigung von Google erhalten zu haben, ist davon auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der Webseite oder Spracheinstellungen auf den europäischen oder amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus weiteren Google-Diensten zusammengeführt. Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt. Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</p>

                    <p>nn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die Daten sobald Sie unsere Seite aufrufen automatisch an Google übermittelt. Um diese Daten wieder zu löschen, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=311162664 kontaktieren.
                        Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und deren Vertreter automatisch Daten erheben, bearbeiten und nutzen.
                    </p>
                    <p>Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf https://developers.google.com/recaptcha/. Google geht hier zwar auf die technische Entwicklung der reCAPTCHA näher ein, doch genaue Informationen über Datenspeicherung und datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute Übersicht über die grundsätzliche Verwendung von Daten bei Google finden Sie in der hauseigenen Datenschutzerklärung auf https://www.google.com/intl/de/policies/privacy/.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Benutzerdefinierte Google Suche Datenschutzerklärung</p>

                    <p>Wir haben auf unserer Website das Google-Plug-in zur benutzerdefinierten Suche eingebunden. Google ist die größte und bekannteste Suchmaschine weltweit und wird von dem US-amerikanische Unternehmen Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) betrieben. Durch die benutzerdefinierte Google Suche können Daten von Ihnen an Google übertragen werden. In dieser Datenschutzerklärung informieren wir Sie, warum wir dieses Plug-in verwenden, welche Daten verarbeitet werden und wie Sie diese Datenübertragung verwalten oder unterbinden können.
                    </p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>Was ist die benutzerdefinierte Google Suche?</p>

                    <p>Das Plug-in zur benutzerdefinierten Google Suche ist eine Google-Suchleiste direkt auf unserer Website. Die Suche findet wie auf www.google.com statt, nur fokussieren sich die Suchergebnisse auf unsere Inhalte und Produkte bzw. auf einen eingeschränkten Suchkreis.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir die benutzerdefinierte Google Suche auf unserer Webseite?</p>

                    <p>Eine Website mit vielen interessanten Inhalten wird oft so groß, dass man unter Umständen den Überblick verliert. Über die Zeit hat sich auch bei uns viel wertvolles Material angesammelt und wir wollen als Teil unserer Dienstleistung, dass Sie unsere Inhalte so schnell und einfach wie möglich finden. Durch die benutzerdefinierte Google-Suche wird das Finden von interessanten Inhalten zu einem Kinderspiel. Das eingebaute Google-Plug-in verbessert insgesamt die Qualität unserer Website und macht Ihnen das Suchen leichter.

                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Welche Daten werden durch die benutzerdefinierte Google Suche gespeichert?</p>

                    <p>Durch die benutzerdefinierte Google-Suche werden nur Daten von Ihnen an Google übertragen, wenn Sie die auf unserer Website eingebaute Google-Suche aktiv verwenden. Das heißt, erst wenn Sie einen Suchbegriff in die Suchleiste eingeben und dann diesen Begriff bestätigen (z.B. auf „Enter“ klicken) wird neben dem Suchbegriff auch Ihre IP-Adresse an Google gesandt, gespeichert und dort verarbeitet. Anhand der gesetzten Cookies (wie z.B. 1P_JAR) ist davon auszugehen, dass Google auch Daten zur Webseiten-Nutzung erhält. Wenn Sie während Ihrem Besuch auf unserer Webseite, über die eingebaute Google-Suchfunktion, Inhalte suchen und gleichzeitig mit Ihrem Google-Konto angemeldet sind, kann Google die erhobenen Daten auch Ihrem Google-Konto zuordnen. Als Websitebetreiber haben wir keinen Einfluss darauf, was Google mit den erhobenen Daten macht bzw. wie Google die Daten verarbeitet.
                        Folgende Cookie werden in Ihrem Browser gesetzt, wenn Sie die benutzerdefinierte Google Suche verwenden und nicht mit einem Google-Konto angemeldet sind:

                    </p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>
                    </p>

                    <p>Name: 1P_JAR
                        Wert: 2020-01-27-13311162664-5
                        Verwendungszweck: Dieses Cookie sammelt Statistiken zur Website-Nutzung und misst Conversions. Eine Conversion entsteht zum Beispiel, wenn ein User zu einem Käufer wird. Das Cookie wird auch verwendet, um Usern relevante Werbeanzeigen einzublenden.
                        Ablaufdatum: nach einem Monat
                    </p>
                    <p>Name: CONSENT
                        Wert: WP.282f52311162664-9
                        Verwendungszweck: Das Cookie speichert den Status der Zustimmung eines Users zur Nutzung unterschiedlicher Services von Google. CONSENT dient auch der Sicherheit, um User zu überprüfen und Userdaten vor unbefugten Angriffen zu schützen.
                        Ablaufdatum: nach 18 Jahren</p>

                    <p>Anmerkung: Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.</p>

                    <p>NName: NID
                        Wert: 196=pwIo3B5fHr-8
                        Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen.
                        Ablaufdatum: nach 6 Monaten</p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Wie lange und wo werden die Daten gespeichert?</p>

                    <p>Die Google-Server sind auf der ganzen Welt verteilt. Da es sich bei Google um ein amerikanisches Unternehmen handelt, werden die meisten Daten auf amerikanischen Servern gespeichert. Unter https://www.google.com/about/datacenters/inside/locations/?hl=de sehen Sie genau, wo die Google-Server stehen.

                    </p>

                    <p>Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Dadurch sind die Daten schneller abrufbar und vor möglichen Manipulationen besser geschützt. Google hat auch entsprechende Notfallprogramme für Ihre Daten. Wenn es beispielsweise bei Google interne technische Probleme gibt und dadurch Server nicht mehr funktionieren, bleibt das Risiko einer Dienstunterbrechung und eines Datenverlusts dennoch gering.

                    </p>
                    <p>Je nach dem um welche Daten es sich handelt, speichert Google diese unterschiedlich lange. Manche Daten können Sie selbst löschen, andere werden von Google automatisch gelöscht oder anonymisiert. Es gibt aber auch Daten, die Google länger speichert, wenn dies aus juristischen oder geschäftlichen Gründen erforderlich ist.</p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>Wie kann ich meinen Daten löschen bzw. die Datenspeicherung verhindern?</p>

                    <p>Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Es gibt einige Daten, die Sie jederzeit löschen können. Wenn Sie ein Google-Konto besitzen, können Sie dort Daten zu Ihrer Webaktivität löschen bzw. festlegen, dass sie nach einer bestimmten Zeit gelöscht werden sollen. In Ihrem Browser haben Sie zudem die Möglichkeit, Cookies zu deaktivieren, zu löschen oder nach Ihren Wünschen und Vorlieben zu verwalten. Hier finden Sie Anleitungen zu den wichtigsten Browsern:
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                        Safari: Verwalten von Cookies und Websitedaten mit Safari
                        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
                        Internet Explorer: Löschen und Verwalten von Cookies
                        Microsoft Edge: Löschen und Verwalten von Cookies
                    </p>

                </div>

                <div>
                    <p className='mt-5 text-xl'></p>

                    <p>Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI. Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung durch Google näherbringen. Wenn Sie mehr darüber erfahren wollen, empfehlen wir die umfangreiche Datenschutzerklärung von Google unter https://policies.google.com/privacy?hl=de.
                    </p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Cookiebot Datenschutzerklärung</p>
                    <p>Wir verwenden auf unserer Website Funktionen des Anbieters Cookiebot. Hinter Cookiebot steht das Unternehmen Cybot A/S, Havnegade 39, 1058 Kopenhagen, DK. Cookiebot bietet uns unter anderem die Möglichkeit, Ihnen einen umfangreichen Cookie-Hinweis (auch Cookie-Banner oder Cookie-Notice genannt) zu liefern. Durch die Verwendung dieser Funktion können Daten von Ihnen an Cookiebot bzw. Cybot gesendet, gespeichert und verarbeitet werden. In dieser Datenschutzerklärung informieren wir Sie warum wir Cookiebot nutzen, welche Daten übertragen werden und wie Sie diese Datenübertragung verhindern können.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>Was ist Cookiebot?</p>

                    <p>Cookiebot ist ein Software-Produkt des Unternehmens Cybot. Die Software erstellt automatisch einen DSGVO-konformen Cookie-Hinweis für unserer Websitebesucher. Zudem scannt, kontrolliert und wertet die Technologie hinter Cookiebot alle Cookies und Tracking-Maßnahmen auf unserer Website.
                    </p>

                </div>

                <div>
                    <p className='mt-5 text-xl'>Warum verwenden wir Cookiebot auf unserer Webseite?</p>
                    <p>Datenschutz nehmen wir sehr ernst. Wir wollen Ihnen ganz genau zeigen, was auf unserer Website vor sich geht und welche Daten von Ihnen gespeichert werden. Cookiebot hilft uns einen guten Überblick über all unserer Cookies (Erst- und Drittanbieter-Cookies) zu erhalten. So können wir Sie über die Nutzung von Cookies auf unserer Website exakt und transparent informieren. Sie bekommen stets einen aktuellen und datenschutzkonformen Cookie-Hinweis und entscheidet selbst, welche Cookies Sie zulassen und welche nicht.
                    </p>
                </div>


                <div>

                    <p className='mt-5 text-xl'></p>
                    <p>IP-Adresse (in anonymisierter Form, die letzten 3 Ziffern werden auf 0 gesetzt)
                        Datum und Uhrzeit Ihres Einverständnisses
                        unsere Website-URL
                        technische Browserdaten
                        verschlüsselter, anonymer Key
                        die Cookies, die Sie zugelassen haben (als Zustimmungsnachweis) Folgenden Cookies werden von Cookiebot gesetzt, wenn Sie der Verwendung von Cookies zugestimmt haben:
                    </p>
                </div>
                <div>

                    <p className='mt-5 text-xl'></p>
                    <p>Name: CookieConsent
                        Wert: stamp:P7to4eNgIHvJvDerjKneBsmJQd9311162664-2
                        Verwendungszweck: In diesem Cookie wird Ihr Zustimmungsstatus, gespeichert. Dadurch kann unsere Website auch bei zukünftigen Besuchen den aktuellen Status lesen und befolgen.
                        Ablaufdatum: nach einem Jahr
                    </p>
                </div>

                <div>

                    <p className='mt-5 text-xl'></p>
                    <p>Name: CookieConsentBulkTicket
                        Wert: kDSPWpA%2fjhljZKClPqsncfR8SveTnNWhys5NojaxdFYBPjZ2PaDnUw%3d%3311162664-6
                        Verwendungszweck: Dieses Cookie wird gesetzt, wenn Sie alle Cookies erlauben und somit eine “Sammelzustimmung” aktiviert haben. Das Cookie speichert dann eine eigene, zufällige und eindeutige ID.
                        Ablaufdatum: nach einem Jahr
                    </p>
                </div>

                <div>

                    <p className='mt-5 text-xl'></p>
                    <p>Anmerkung: Bitte bedenken Sie, dass es sich hier um eine beispielhafte Liste handelt und wir keinen Anspruch auf Vollständigkeit erheben können. In der Cookie-Erklärung unter https://www.cookiebot.com/de/cookie-declaration/ sehen Sie, welche weiteren Cookies eingesetzt werden können.</p>
                </div>

                <div>

                    <p className='mt-5 text-xl'></p>
                    <p>Laut der Datenschutzerklärung von Cybot verkauft das Unternehmen personenbezogene Daten nicht weiter. Cybot gibt allerdings Daten an vertrauensvolle Dritt- oder Subunternehmen weiter, die dem Unternehmen helfen, die eigenen betriebswirtschaftlichen Ziele zu erreichen. Daten werden auch dann weitergegeben, wenn dies rechtlich erforderlich ist.
                    </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>
                        Wie lange und wo werden die Daten gespeichert?
                    </p>
                    <p>
                        Alle erhobenen Daten werden ausschließlich innerhalb der Europäischen Union übertragen, gespeichert und weitergeleitet. Die Daten werden in einem Azure-Rechenzentrum (Cloud-Anbieter ist Microsoft) gespeichert. Auf https://azure.microsoft.com/de-de/global-infrastructure/regions/ erfahren Sie mehr über alle „Azure-Regionen“. Alle User Daten werden von Cookiebot nach 12 Monaten ab der Registrierung (Cookie-Zustimmung) bzw. unmittelbar nach Kündigung des Cookiebot-Services gelöscht.
                    </p>
                </div>


                <div>
                    <p className='mt-5 text-xl'>
                    </p>
                    <p>
                        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern? Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und sie auch zu löschen. Die Datenerfassung und Speicherung können Sie beispielsweise verhindern, indem Sie über den Cookie-Hinweis die Verwendung von Cookies ablehnen. Eine weitere Möglichkeit die Datenverarbeitung zu unterbinden bzw. nach Ihren Wünschen zu verwalten, bietet Ihr Browser. Je nach Browser funktioniert die Cookie-Verwaltung etwas anders. Hier finden Sie die Anleitungen zu den momentan bekanntesten Browsern:                    </p>
                </div>
                <div>
                    <p className='mt-5 text-xl'>
                    </p>
                    <p>
                        Chrome: Cookies in Chrome löschen, aktivieren und verwalten
                        Safari: Verwalten von Cookies und Websitedaten mit Safari
                        Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
                        Internet Explorer: Löschen und Verwalten von Cookies
                        Microsoft Edge: Löschen und Verwalten von Cookies                    
                        </p>
                </div>

                <div>
                    <p className='mt-5 text-xl'>
                    </p>
                    <p>
                    Wenn Sie mehr über die Datenschutzrichtlinien von „Cookiebot“ bzw. dem dahinterstehenden Unternehmen Cybot erfahren wollen, empfehlen wir Ihnen die Datenschutzrichtlinien unter https://www.cookiebot.com/de/privacy-policy/ durchzulesen.                    
                        </p>
                </div>

                <div>
                    <p className='mt-16 text-lg'>
                    Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in Kooperation mit slashtechnik.de
                    </p>

                </div>





            </div>
            <Footer />
        </>


    )
}

export default Datenschutz;