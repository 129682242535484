import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';

import Bürokraft from '../../images/Jobs/Burokraft.jpg'
import Monteur from '../../images/Jobs/Monteur.jpg'
import Fahrer from '../../images/Jobs/Fahrer Image.jpg'
import Umzugshelfer from '../../images/Jobs/Umzugshelfer.jpg'
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';



const JobsPage = () => {
  return (
    <section>
      <ScrollToTop />
      <NavBar />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col">
            <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
              {/* <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Space The Final Frontier</h1>
              <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p> */}
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">

            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Bürokraft} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Bürokraft</h2>
              <p className="text-base leading-relaxed mt-2">
                Ob nah oder fern – ein reibungsloser und stressfreier
                Umzug, das ist der Anspruch, dem wir uns stellen,
                deutschland- und europaweit oder einfach nur innerhalb
                von Rhein/Ruhrgebiet. Unser Umzugsservice auf einen
                Blick: Vom günstigen Studentenumzug, wenn Sie selbst
                mit anpacken, bis hin zum "All-Inclusive-Umzug"..
              </p>
              <Link to='/Job/Burokraft' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Umzugshelfer} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Monteur</h2>
              <p className="text-base leading-relaxed mt-2">Bekommen Sie umzüge von verschiedenen Behörden finanziert?</p>
              <Link to='/Job/Monteur' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Monteur} />
              </div>Fahrer
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Fahrer</h2>
              <p className="text-base leading-relaxed mt-2">
                Swag shoivdigoitch literally meditation
                subway tile tumblr cold-pressed. Gastropub
                street art beard dreamcatcher neutra, ethical
                XOXO lumbersexual.
              </p>
              <Link to='/Job/Fahrer' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Fahrer} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Umzugshelfer</h2>
              <p className="text-base leading-relaxed mt-2">
                Swag shoivdigoitch literally meditation subway
                tile tumblr cold-pressed. Gastropub street art
                beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>
              <Link to='/Job/Umzugshelfer' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default JobsPage;
