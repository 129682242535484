import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Umzugsservice/Transport page.jpg'


const Transport = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master p-2'>
                                Möbel- & Küchentransporte
                                </p>
                                <p className=' p-2'>
                                Diverse Transporte zwischen einzelnen Haushalten liegen 
                                für uns an der Tagesordnung. Unsere Helfer stehen Ihnen 
                                stets tatkräftig zur Seite.
                                </p>

                                <p className='text-2xl mb-2 text-orange-master p-2'>
                                Abholungen jeder Art
                                </p>
                                <p className=' p-2'>
                                Ihnen ist der Transport-Service des Möbelhauses zu teuer 
                                oder Sie wissen nicht, wie Sie das Möbelstück vom 
                                eBay Verkäufer zu sich nach Hause
                                 transportieren sollen? Genau in diesem Fall bieten wir Ihnen 
                                 eine preisgünstige Alternative.
                                </p>

                                <p className='text-2xl mb-2 text-orange-master p-2'>
                                Sonderwünsche
                                </p>
                                <p className=' p-2'>
                                Auch Sonderwünschen sehen wir gelassen entgegen. 
                                Durch unsere Erfahrung und unser Partnernetzwerk 
                                können wir Ihnen in vielen Fällen eine Lösung vorschlagen.
                                </p>

                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
                                        <thead className="text-xs text-white uppercase bg-orange-master dark:text-white">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                Transport	
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                Preis
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium  text-black whitespace-nowrap dark:text-blue-100">
                                                1 LKW + Fahrer (20m³)	
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                € 38,-   / Std. 

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                2 LKW's + Fahrer (40m³)	
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                € 80,-   / Std.


                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                pro Ladehelfer	
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                € 24,-   / Std.


                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black text-blue-50 whitespace-nowrap dark:text-blue-100">
                                                Fahrt (einfach)	
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                €  1,90 / KM
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                </div>
                                <p className='flex justify-center text-sm font-bold text-black'>Jegliche Stunde, die über der gebuchten Zeit liegt wird verdoppelt.</p>

                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Transport;
