import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import { useDocTitle } from '../../components/CustomHook';
import Mobelmontage from '../../images/Umzugsservice/Mobelmontage.jpg'
import Transport from '../../images/Umzugsservice/Transport.jpg'
import Auspackservice from '../../images/Umzugsservice/Auspackservice.jpg'
import Klaviertransport from '../../images/Umzugsservice/Klaviertransport.jpg'
import einlagern from '../../images/Umzugsservice/einlagern.jpg'
import Umzugslift from '../../images/Umzugsservice/Umzugslift.jpg'
import Halteverbot from '../../images/Umzugsservice/Halteverbot.jpg'
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';

const SimplePage = () => {
  useDocTitle("1A Euroumzug");
  return (
    <section>
      <ScrollToTop />
      <NavBar />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col">
            <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Mobelmontage} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Küchen- und Möbelmontage</h2>
              <p className="text-base leading-relaxed mt-2">Egal ob Privat  Büro  Senioren Behörden Firmenumzug. Mit uns erfahren Sie einen professionellen Ablauf. Selbst bei einfachem  Transport  helfen wir Ihnen gerne! </p>
              <Link to='/umzugsservice/Kuchen' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Transport} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Transport</h2>
              <p className="text-base leading-relaxed mt-2">Ob nah oder fern – ein reibungsloser und stressfreier Umzug, das ist der Anspruch, dem wir uns stellen, deutschland- und europaweit oder einfach nur innerhalb von Rhein/Ruhrgebiet .</p>
              <Link to='/umzugsservice/Transport' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Auspackservice} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Ein und Auspackservice</h2>
              <Link to='/umzugsservice/Auspackservice' className="text-indigo-500 inline-flex items-center mt-3">
                Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Klaviertransport} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Klaviertransport</h2>
              <Link to='/umzugsservice/Klavier' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={einlagern} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Möbel einlagern</h2>
              <Link to='/umzugsservice/Mobel_einlagern' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Umzugslift} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Umzugslift</h2>
              <Link to='/umzugsservice/Umzugslift' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Halteverbot} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Halteverbot einrichten</h2>
              <Link to='/umzugsservice/Halteverbot' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>

          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default SimplePage;
