import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import posthog from 'posthog-js';
import { BrowserRouter } from "react-router-dom";

posthog.init(
  '<ph_project_api_key>', { api_host: '<ph_instance_address>' }
)
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
