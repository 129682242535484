import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import whatsappIcon from '../images/whatsapp icon.jpg'
import { FaHome } from "react-icons/fa";
import { IoMdPhonePortrait } from "react-icons/io";
import { FaPhone }from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import phoneicon from "./../images/phone-call-icon.png"
import CookieBanner from './cookies/CookieBanner';

import posthog from 'posthog-js'

const Footer = () => {
    return (
        <>            
            <div className="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
                    <a title="Nachricht an uns" href="https://wa.me/4915254123456" 
                    className="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
                        <img 
                        className="bg-transparent	 
                        object-cover object-center w-full h-full rounded-full" 
                        src={whatsappIcon} 
                         alt='whatsapp icon'   
                        />
                    </a>
            </div>

            <div className="flex items-end justify-end fixed bottom-20 right-0 mb-4 mr-4 z-10">
                    <a 
                    className="block w-16 h-16 rounded-full transition-all transform hover:scale-110 hover:rotate-12"
                    target='_blanck'
                    href={`tel:+4923259085475`}
                    > 
                     
                    <img
                        className="bg-transparent object-cover object-center w-full h-full rounded-fulll" 
                        src={phoneicon}
                         alt='call icon'
                    />
                    </a>
                </div>
            <footer>
                <div className="footer max-w-full mx-auto  bg-gray-100 border-t border-b py-30">

                    <div className="flex flex-wrap h-10 bg-orange-master items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full  md:w-5/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-600 font-semibold py-1">

                            </div>
                        </div>
                    </div>
                    <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
                        <div className="col-span-12 lg:col-span-4">
                            <div className="box-border  p-8  text-center rounded-lg xl:w-80 mx-auto">
                                <h3 className="font-bold text-orange-master flex text text-4xl mb-4 gap-1">1A <p className='text-gray-900'>Euroumzug</p></h3>
                                <div className='text-md font-medium text-gray-600'>
                                    <h5>1A Umzug - Ihr Partner für erstklassige Umzüge! Verlassen Sie sich auf unsere Profis, wenn es darum geht, Ihren Umzug mühelos und sorgenfrei zu gestalten.</h5>
                                </div>
                            </div>
                        </div>
                        {/* 2nd block */}
                        <div className="col-span-6 text-start md:col-span-6 lg:col-span-1 ml-7 mx-auto">
                            <h6 className="text-[#0f172a] text-xl font-bold mb-4">ABTEILUNGEN</h6>
                            <ul className="text-md ml-2 ">
                                <li className="mb-2">
                                    <HashLink to="/umzuge/Privateumzuge" className="text-[#3f3f46] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Privateumzüge</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/umzuge/Behordenumzuge" className="text-[#3f3f46] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Behördeumzüge</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/umzuge/Firmenumzuge" className="text-[#3f3f46] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Firmenumzüge</HashLink>
                                </li>
                                <li className="mb-2">
                                    <HashLink to="/umzuge/Seniorenumzuge" className="text-[#3f3f46] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Seniorenumzüge</HashLink>
                                </li>
                            </ul>
                        </div>
                        {/* 3rd block */}
                        <div className="col-span-6 text-center md:col-span-6 lg:col-span-4 mx-auto ">
                            <h6 className="text-[#0f172a] text-xl font-bold mb-4">KONTAKT</h6>
                            <ul className="text-md">
                                <li className="mb-2 ">
                                        <div className='flex flex-col'>
                                            <div className='flex items-center'>
                                                <FaHome />
                                                <div className='ml-2'>Herzogstraße 10A</div>
                                            </div>
                                            <p className='text-left ml-6'>44651 Herne</p>

                                        </div>
                                </li>
                                <li className="mb-2">
                                        <div className='flex items-center'>
                                            <FaPhone />
                                            <a target='_black' href={`tel:+4923259085475`} className='ml-2'>+49 23259085475</a>
                                        </div>
                                </li>
                                <li className="mb-2">
                                        <div className='flex items-center'>
                                            <IoMdPhonePortrait />
                                            <a target='_black' href={`tel:+4915254123456`} className='ml-2'>+49 15254123456</a>
                                        </div>
                                </li>
                                <li className="mb-2">
                                        <div className='flex items-center'>
                                            <MdEmail />
                                            <a href={`mailto:info@1aeuroumzug.de`} className='ml-2'>info@1aeuroumzug.de</a>
                                        </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-blue-900">
                            <div className="text-xl text-[#0f172a] mb-6">
                                WICHTIGE LINKS:
                            </div>
                            <Link to='/datenschutz'>
                                    <div className="text-md text-[#3f3f46] hover:text-gray-900 hover:tracking-wider font-medium mb-6">

                                        Datenschutz
                                    </div>
                            </Link>
                            <Link to='/impressum'>
                                    <div className="text-md text-[#3f3f46] hover:text-gray-900 hover:tracking-wider font-medium mb-6">
                                        Impressum
                                    </div>
                            </Link>
                            <Link to='/AGB'>
                                    <div className="text-md text-[#3f3f46] hover:text-gray-900 hover:tracking-wider font-medium mb-6">
                                        AGB
                                    </div></Link>
                            <div className="mx-auto text-center mt-2">
                                <ul className="flex justify-center mb-4 md:mb-0">
                                    <li className="ml-4">
                                        <a href='https://www.facebook.com/1aeuroumzug' target='_blanck' className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out" aria-label="Facebook">
                                            <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
                        <div className="w-full md:w-5/12 px-4 mx-auto text-center py-2">
                            <div className="text-sm text-gray-600 font-semibold py-1">
                                Copyright &copy; {new Date().getFullYear()}{"  "}
                                <HashLink
                                    to="/"
                                    className=" hover:text-gray-900"
                                >
                                    1aeuroumzug
                                </HashLink>. Alle Rechte vorbehalten.
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
            {posthog.has_opted_out_capturing()
                || posthog.has_opted_in_capturing()
                ? null
                : <CookieBanner />
            }
        </>
    )
}
export default Footer;

