import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Jobs/Monteur image.jpg'


const Monteur = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master'>
                                    Monteur
                                </p>
                                <p className='mt-1'>
                                    Wir suchen Monteure m/w/d
                                </p>
                                <p className='mt-2'>
                                    Wir bieten einen interessanten und abwechslungsreichen,
                                    unbefristeten Job, in einem angenehmen Umfeld, mit einer fairen Behandlung!
                                    Das wichtigste sind unsere Mitarbeiter, auf diese stützt sich unser ganzes Unternehmen.
                                    Entsprechen gehen wir mit diesen, uns wichtigen Menschen,
                                    um. Wir bezahlen fair, pünktlich und regelmäßig.

                                </p>
                                <p className='mt-1'>
                                    Ihre Aufgaben:
                                </p>
                                <ul className='ml-2 mt-2 list-disc list-inside pace-y-4 text-gray-500 list-disc list-inside dark:text-gray-400'>
                                    <li className='mr-4'>Die Montage von Möbelstücken</li>
                                    <li className='mr-4'>Sicherer und freundlicher Kontakt mit dem Kunden beim Umzug</li>
                                    <li className='mr-4'>Ordentliches Ab-und Aufbauen der Möbelstücke</li>
                                    <li className='mr-4'>Küchenmontage</li>
                                </ul>
                                <p className='mt-2'>
                                    Ihr Profil:
                                </p>
                                <ul className='ml-2 mt-2 list-disc list-inside pace-y-4 text-gray-500 list-disc list-inside dark:text-gray-400'>
                                    <li className='mr-4'>Berufserfahrung im Bereich Umzüge von Vorteil.</li>
                                    <li className='mr-4'>Ein gepflegtes Erscheinungsbild setzen wir voraus sowie Kundenorientierung, Zuverlässigkeit, Pünktlichkeit, Teamfähigkeit und</li>
                                    <li className='mr-4'>körperliche Belastbarkeit ist unabdingbar.</li>
                                </ul>


                                <p className='mt-4'>
                                    Haben wir Interesse geweckt?
                                </p>
                                <p >
                                    ewerben Sie sich jetzt unter info@1aeuroumzug.de
                                </p>
                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Monteur;
